<template>
    <default-template
        title="温熱・省エネ設備機器ポータルサイト(住宅版)"
        :is-visible-public="true"
        :is-visible-logout="true"
        :is-visible-pass-update="true"
        :is-visible-jigyosya-login="false"
        :is-house="true"
        :is-visible-user-header="isOrganization"
    >
        <div class="bl_contents_wrapper">
            <div class="bl_underHeader ly_underHeader">
                <h2 class="el_lv2Heading">{{deviceCategoryName}}公開日等変更</h2>
            </div>
            <error-list />
            <date-form />
            <div class="ly_buttonBox">
                <app-button
                    name="キャンセル"
                    @click="back"
                    class="cancel"
                    ref="jest-device-date-back"
                />
                <app-button
                    name="変更"
                    @click="submit"
                    ref="jest-device-date-submit"
                />
            </div>
        </div>
    </default-template>
</template>

<script>
/**
 * P145_コージェネレーション機器公開日等変更画面
 *
 * [イベント処理]
 * back...表示中のページに応じた遷移先に戻る
 * updatePublishDate...機器種別に応じた情報公開日等の変更アクションを返す
 * submit...機器種別に応じた情報公開日等の変更処理を実行する
 */
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import device from '@/mixins/device'
import { move, mapComputedProperties } from '@/util'
import ErrorList from '@/components/molecules/ErrorList'
import DateForm from '@/components/organisms/DateForm.vue'

export default {
    name:'OrganizationDeviceUpdatePublishDate',
    components:{
        ErrorList,
        DateForm
    },
    mixins:[device],
    computed:{ 
        ...mapGetters({
            cogenerationsPublishDates:'cogenerations/publishDates',
            hybridWaterHeatersPublishDates:'hybridWaterHeaters/publishDates',
            electricHeatPumpsPublishDates:'electricHeatPumps/publishDates',
            solarSystemsPublishDates:'solarSystems/publishDates',
            solarWaterHeatersPublishDates:'solarWaterHeaters/publishDates',
        }),
        ...mapComputedProperties([
            'publish_date',
            'release_date',
        ]),
        /**
         * 機器に対応した編集対象のデータ
         */
        data(){
            return {
                cogenerations:this.cogenerationsPublishDates,
                hybridWaterHeaters:this.hybridWaterHeatersPublishDates,
                electricHeatPumps:this.electricHeatPumpsPublishDates,
                solarSystems:this.solarSystemsPublishDates,
                solarWaterHeaters:this.solarWaterHeatersPublishDates,
            }[this.$route.meta.deviceCategoryKey]
        },
        /**
         * 事業者ページか否か
         */
        isOrganization() {
            return this.$route.meta.routeType === 'organization'
        },
        isEditablePublishDate(){
            return moment(this.publish_date).isSameOrAfter(moment())
        },
    },
    mounted(){
        this.clearError()
    },
    methods:{
        ...mapActions({
            clearError:'error/clear',
            updateError:'error/update',
        }),
        /**
         * 表示中のページに応じた遷移先に戻る
         */
        back(){
            const path = this.$route.path.replace('/update/publish_date', '')
            move(this.$router, path)
        },
        /**
         * 機器種別に応じた情報公開日等の変更アクションを返す
         */
        updatePublishDate(){
            return this.$store.dispatch(
                this.$route.meta.deviceCategoryKey + '/updatePublishDate',
                this.isEditablePublishDate
            )
        },
        /**
         * 機器種別に応じた情報公開日等の変更処理を実行する
         */
        submit(){
            this.clearError()
            return this.updatePublishDate()
                .then(response => {
                    if (response.status == 200) {
                        this.updateError(response)
                        this.back()
                    } else {
                        this.updateError(response)
                    }
                })
        }
    }
}
</script>

<style scoped>
.bl_contents_wrapper >>> .uq_detaForm{
    min-width: 600px;
}
.bl_contents_wrapper >>> .uq_detaForm .formrow{
    grid-template-columns: 9em 1fr;
    -ms-grid-columns: 9em 1fr;
}
</style>