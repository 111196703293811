<template>
    <div class="ly_form_box uq_detaForm">
        <p class="uq_detaForm_deviceName">{{ deviceName }}</p>
        <div class="bl_input_container">
            <app-text
                name="publish_date"
                v-model="publish_date"
                label="情報公開日"
                class="formrow"
                type="date"
                ref="jest-date-publish-date"
                :disabled="isDisabledPublishDate"
            />
        </div>
        <div class="bl_input_container">
            <app-text
                name="release_date"
                v-model="release_date"
                label="発売予定年月日"
                class="formrow"
                type="date"
                ref="jest-date-release-date"
            />
        </div>
    </div>
</template>

<script>
/**
 * 公開日等変更情報の入力フォーム
 * 
 * [算出プロパティ]
 * data...機器に対応した編集対象のデータのゲッター
 * deviceDetail...機器に応じた機器詳細のゲッター
 * deviceName...フォーム上部に表示する機器の名称
 * 
 * [イベント処理]
 * getDevice...機器に応じた機器詳細取得アクションを返す
 */
import { mapGetters } from 'vuex'
import { mapComputedProperties, move } from '@/util'
import moment from 'moment'
import Progress from '@/mixins/progress.js'
import device from '@/mixins/device'

export default {
    name:'DateForm',
    mixins:[Progress, device],
    computed:{
        ...mapGetters({
            cogenerationsPublishDates:'cogenerations/publishDates',
            cogenerationDetail:'cogenerations/cogenerationDetail',
            hybridWaterHeatersPublishDates:'hybridWaterHeaters/publishDates',
            hybridWaterHeaterDetail:'hybridWaterHeaters/hybridWaterHeatersDetail',
            electricHeatPumpsPublishDates:'electricHeatPumps/publishDates',
            electricHeatPumpsDetail:'electricHeatPumps/electricHeatPumpsDetail',
            solarSystemsPublishDates:'solarSystems/publishDates',
            solarSystemsDetail:'solarSystems/solarSystemDetail',
            solarWaterHeatersPublishDates:'solarWaterHeaters/publishDates',
            solarWaterHeatersDetail:'solarWaterHeaters/solarWaterHeaterDetail',
        }),
        ...mapComputedProperties([
            'publish_date',
            'release_date',
        ]),
        /**
         * 機器に対応した編集対象のデータ
         */
        data(){
            return {
                cogenerations:this.cogenerationsPublishDates,
                hybridWaterHeaters:this.hybridWaterHeatersPublishDates,
                electricHeatPumps:this.electricHeatPumpsPublishDates,
                solarSystems:this.solarSystemsPublishDates,
                solarWaterHeaters:this.solarWaterHeatersPublishDates,
            }[this.$route.meta.deviceCategoryKey]
        },
        /**
         * 機器に応じた機器詳細のゲッター
         */
        deviceDetail(){
            return {
                cogenerations:this.cogenerationDetail,
                hybridWaterHeaters:this.hybridWaterHeaterDetail,
                electricHeatPumps:this.electricHeatPumpsDetail,
                solarSystems:this.solarSystemsDetail,
                solarWaterHeaters:this.solarWaterHeatersDetail,
            }[this.$route.meta.deviceCategoryKey]
        },
        /**
         * フォーム上部に表示する機器の名称
         */
        deviceName(){
            return {
                cogenerations:this.cogenerationAndHybridName,
                hybridWaterHeaters:this.cogenerationAndHybridName,
                electricHeatPumps:this.electricHeatPumpName,
                solarSystems:this.solarSystemName,
                solarWaterHeaters:this.solarWaterHeaterName,
            }[this.$route.meta.deviceCategoryKey]
        },
        cogenerationAndHybridName(){
            return this.deviceDetail.brand_name ? ((this.deviceDetail.brand_name.name ?? '-')
                + '/' + (this.deviceDetail.pu_number ?? '-')
                + '/' + (this.deviceDetail.tu_number ?? '-')
                + '/' + (this.deviceDetail.bb_number ?? '-'))
                : null
        },
        electricHeatPumpName(){
            return this.deviceDetail.brand_name ?
            ((this.deviceDetail.brand_name.name ?? '-')
            + '/' + (this.deviceDetail.system_number ?? '-')
            + '/' + (this.deviceDetail.pu_number ?? '-')
            + '/' + (this.deviceDetail.tu_number ?? '-'))
            : null
        },
        solarSystemName(){
            return this.deviceDetail.brand_name ?
            ((this.deviceDetail.brand_name.name ?? '-')
            + '/' + (this.deviceDetail.system_type ?? '-')
            + '/' + (this.deviceDetail.heat_collector_type ?? '-')
            + '/' + (this.deviceDetail.heat_storage_tank_type ?? '-'))
            : null
        },
        solarWaterHeaterName(){
            return this.deviceDetail.brand_name ?
            ((this.deviceDetail.brand_name.name ?? '-')
            + '/' + (this.deviceDetail.system_type ?? '-'))
            : null
        },
        isDisabledPublishDate(){
            return moment(this.publish_date).isBefore(moment())
        }
    },
    async mounted(){
        const loader = this.$loading.show()
        this.startProgress()
        // 機器詳細データを取得する
        await this.getDevice()
        // データを取得できなかった場合はNot Foundを表示
        if (Object.keys(this.deviceDetail).length == 0) {
            move(this.$router, '/NotFound')
        }
        this.finishProgress()
        loader.hide()
        this.update({
            publish_date:moment(this.deviceDetail.publish_date).format('YYYY-MM-DD'),
            release_date:moment(this.deviceDetail.release_date).format('YYYY-MM-DD'),
        })
    },
    methods:{
        update(values){
            this.$store.dispatch(this.$route.meta.deviceCategoryKey + '/update', values)
        },
        /**
         * 機器に応じた機器詳細取得アクションを返す
         */
        getDevice(){
            return this.$store.dispatch(this.$route.meta.deviceCategoryKey + '/getDeviceDetail', {id:this.$route.params.id, isPublic:false})
        },
    }
}
</script>

<style scoped>
.ly_form_box >>> div.formrow:last-of-type {
    border-bottom:0px;
}
.uq_detaForm{
    display: flex;
    flex-direction: column;
    padding: 32px;
}
.uq_detaForm_deviceName{
    display: block;
    margin-top: 0;
}
</style>